<template>
  <layout :title="$route.name">
    <div class="expande-horizontal centraliza">
      <v-icon :color="$theme.primary">mdi-star</v-icon>
      <h1 class="mx-2 fonte white--text">Ranking</h1>
      <v-icon :color="$theme.primary">mdi-star</v-icon>
    </div>
    <div class="expande-horizontal centraliza pa-2">
      <v-flex xs12 md6>
        <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 20px;" class="text-left fonte">
                    PONTOS
                  </th>
                  <th class="text-left fonte">
                    NOME
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in getUserRanking.docs"
                  :key="item._id"
                >
                  <td class="font-weight-bold">{{ item.balance_token }}</td>
                  <td class="font-weight-bold">{{ item.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </v-flex>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tabs:0
    }
  },
  computed: {
    ...mapGetters(["getUserRanking"])
  },
  methods: {
    ...mapActions([
      "listRanking"
    ])
  },
  created() {
    this.listRanking()
  }
};
</script>
